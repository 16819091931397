import { createContext, type PropsWithChildren } from 'react';

import { useUserInfo } from '~/hooks/use-user-info';

import type { SimpleFeatureFlag } from '~/types';

// Create Context Object
export const FeatureFlagsContext = createContext<{
  featureFlags: any[];
  hasFeatureFlag: (flagName: string) => 'waiting' | SimpleFeatureFlag | undefined;
}>({
  featureFlags: [],
  hasFeatureFlag: () => undefined,
});

// Create a provider for components to consume and subscribe to changes
export const FeatureFlagsProvider = ({ children }: PropsWithChildren<{}>) => {
  const { featureFlags } = useUserInfo() || [];

  const hasFeatureFlag = (flagName: string) => {
    return featureFlags?.find(({ name }: SimpleFeatureFlag) => name === flagName);
  };

  return (
    <FeatureFlagsContext.Provider
      value={{
        featureFlags,
        hasFeatureFlag,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};
