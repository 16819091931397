import { useFeatureFlags } from './use-feature-flags';

export const useFeatureFlag = (flagName: string) => {
  const { hasFeatureFlag } = useFeatureFlags();

  const hasAccess = (flagName: string) => {
    const hasFlag = hasFeatureFlag(flagName);
    return hasFlag && hasFlag !== 'waiting';
  };

  return hasAccess(flagName);
};
